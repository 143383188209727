/* this file is transformed by vux-loader */
/* eslint-disable */
import Vue from 'vue';
import router from '@/router/index';
import listItem from '@/components/common/ListItem';
Vue.component(listItem.name, listItem);
export default {
  data() {
    return {
      newObject: this.$route.params.newsItem
    };
  },
  methods: {
    addNewsReadTime() {
      var url = _baseUrl + "/mobile/oa/addNewsReadTime.do";
      this.$ajax({
        url: url,
        method: 'POST',
        params: {
          newsId: JSON.stringify(this.newObject.newId)
        }
      }).then(response => {}).catch(error => {
        console.log(error);
      });
    }
  },
  created() {
    this.addNewsReadTime();
  }
};